$backgroundColor: rgba(40 44 52 / 80%);
$fontSizeContent: 16px;

body {
  background: url(./background-03.png) no-repeat 50%  50%;
  background-size: cover;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: 767px) {
    background-size: auto;
  }

}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

.app {
  text-align: center;
  color: white;
  background-color: $backgroundColor;

  min-height: 100vh;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .appHeader {
    font-size: calc(10px + 2vmin);
    margin-bottom: 50px;
    > p.title {
      margin-bottom: 0;
    }
    @media (max-width: 767px) {
      margin-bottom: 30px;
      font-weight: bold;
    }
  }

  .wrapper-form {
    background: rgba(255, 255, 255, .1);
    padding: 30px;
    border-radius: 12px;
    @media (max-width: 767px) {
      padding: 15px 30px;
      width: 100%;
    }
  }

  .ant-form {
    width: 390px;
    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .ant-form, .ant-form-item-label > label {
    font-size: $fontSizeContent;
    color: #fff;
    text-align: left;
    
    ::before {
      position: absolute;
      right: -16px;
    }
  }

  .ant-form-item .ant-select .ant-select-selector,
  .ant-form-item .ant-form-item-control .ant-input,
  .ant-form-item .ant-form-item-control-input span {
    font-size: $fontSizeContent;
    border-radius: 6px;

    &::placeholder {
      color: #777;  
    }
    &::-webkit-input-placeholder {
      color: #777;
    }
    &:-ms-input-placeholder {
      color: #777;
    }
  }

  .wapper-captcha {
    position: relative;
    border-radius: 6px;

    .ant-form-item-control-input-content {
      background: #fff;
      border-radius: 6px;
      overflow: hidden;
    }
    
    canvas {
      position: relative;
      top: 5px;
      
      @media (max-width: 767px) {
        width: 120px;
      }
    }

    a {
      user-select: none;
      position: absolute;
      top: 7px;
      right: 13px;
      font-weight: bold;

      &:hover {
        color: #03706a !important;
      }
    }
  }

  .success-note {
    font-size: $fontSizeContent;
    margin-bottom: 30px;
    > p:not(:last-child) {
      margin: 0;
    }
  }

  .btn-register {
    text-align: center; 
    margin-top: 50px;

    > button.ant-btn {
      text-transform: uppercase;
      line-height: 1;
      width: 100%;
      height: auto;
      padding: 10px 0;
      box-sizing: content-box;
      background: #01837b;
      border-radius: 6px;
      border: none;

      &:hover {
        opacity: .7;
        background: #03706a;
      }
    }
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }
  @media (max-width: 767px) {
    padding: 15px;
    width: 100%;
  }
}

.appLogo {
  position: fixed;
  top: 30px;
  left: 30px;

  > img {
    height: 40vmin;
    max-height: 150px;
    pointer-events: none;
  }

  @media (max-width: 767px) {
    position: unset;
    text-align: center;
    padding-bottom: 30px;
  }
}

